import React, { Fragment, useContext, useEffect } from 'react';
import PostsContext from '../../context/posts/postsContext';
import Loader from '../layout/Loader';
import PostDetails from '../posts/postDetails';
const Home = () => {
    const postsContext = useContext(PostsContext);
    const { getPosts, posts, loading } = postsContext;
    useEffect(() => {
        getPosts();
    }, [])
    console.log('wait wehave post', posts);
    return (
        <Fragment>
            <div className="back-in-business" style={{display: "none"}}>
                <h1>Are we in or not?</h1>
                {(loading) ? <Loader /> : posts.map((post, idx) => (
                    <PostDetails key={`${idx}`} post={post} />
                ))}
            </div>
        </Fragment>

    )
}

export default Home