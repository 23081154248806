import React from 'react'
 import PropTypes from 'prop-types';
import './Repo.css';
 
const Repos = () => {
     return (
        <div className="repo-list">
           
        </div>
    )
}
Repos.prototype = {
    repos: PropTypes.array.isRequired,
}

export default Repos;