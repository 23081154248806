import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class NotFound extends Component {
    render() {
        return (
            <div>
                <h4>Not Found</h4>
                <p className='not-found'> Page is not on the app DuDe?</p>

                <Link to={`/`} className="btn btn-light btn-s my-1">Go Home</Link>

            </div>
        );
    }
}

export default NotFound;