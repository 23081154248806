export const SEARCH_USERS = 'SEARCH_USERS';
export const SET_USER = 'SET_USER';
export const SET_USERS = 'SET_USERS';
export const SET_ALERT = 'SET_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';
export const CLEAR_USERS = 'CLEAR_USERS';

export const SET_POSTS = 'SET_POSTS';
export const SET_POST = 'SET_POST';
export const CLEAR_POSTS = 'CLEAR_POSTS';
export const CLEAR_POST = 'CLEAR_POST';

export const SET_LOADING = 'SET_LOADING';
export const REMOVE_ALERT = 'REMOVE_ALERT';