import React, { useReducer } from 'react';
import Axios from 'axios';
import PostsReducer from './postsReducer';
import PostsContext from './postsContext';
import { SEARCH_USERS, SET_LOADING, CLEAR_USERS, SET_POSTS, SET_USER } from '../types';
import { API, graphqlOperation } from 'aws-amplify'
import { listPosts } from '../../graphql/queries';

const PostsState = props => {

    const initialState = {
        posts: [],
        post: {},
        loading: false,
        alert: null
    }
    const [state, dispatch] = useReducer(PostsReducer, initialState);

    // Get Posts
    const getPosts = async () => {
        setLoading();

        const results = await API.graphql(graphqlOperation(listPosts));
        const { items } = results.data.listPosts;
        console.log('showing', items);
        setPosts(items);
    }

    // Get Post
    const getPost = async (postId) => {

    }


    // Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    // Set Users
    const setPosts = (newState) => dispatch({ type: SET_POSTS, payload: newState });

    // Clear Users
    const clearUsers = () => dispatch({ type: CLEAR_USERS });

    return (
        <PostsContext.Provider
            value={{
                posts: state.posts,
                post: state.post,
                loading: state.loading,
                alert: state.alert,
                getPosts,
                getPost,
                setLoading

            }}
        >
            {props.children}
        </PostsContext.Provider>
    )
}
export default PostsState;