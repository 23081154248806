import React from 'react';
import { Link } from 'react-router-dom';

const About = () => {
    return (
        <div>
            <h1>About this App</h1>
            <p>App to search Github users</p>
            <p>Verssion 1.0.0</p>
            <Link to={`/`} className="btn btn-dark btn-s my-1">Go Home</Link>
        </div>

    )
}

export default About;