import React, { useEffect, Fragment, useContext } from 'react'
import Loader from '../layout/Loader';
import Repos from "../repos/Repos";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
 
const UserDetails = ({ match }) => {
 
    useEffect(() => {
   
    }, []);


     return (
  <div>
      <h1>User Details</h1>
        </div>
    )
};

export default UserDetails
