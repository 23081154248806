import React from 'react'
import {Link} from 'react-router-dom';
import './postDetails.css';

const PostDetails = ({post: { id, postOwnerUsername, postTitle, postBody, postOwnerId, updatedAt}}) => {
     return (
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">{postTitle}</h5>
                <p className="card-text">{postBody}</p>
               
                <Link to={`/`} className="btn btn-dark btn-s my-1">{id}</Link>
                <Link to={`/about`} className="btn btn-dark btn-s my-1">{postOwnerUsername}</Link>
                <Link to={`/notfound`} className="btn btn-dark btn-s my-1">{postOwnerId}</Link>
                <Link to={`/notfound`} className="btn btn-dark btn-s my-1">{updatedAt}</Link>

            </div>
        </div>
    )
}

export default PostDetails;
