/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://7zznov2gb5bclbgcavl7pkawve.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-pkq2nzukmjaubdxxs656lb7ide",
    "aws_cognito_identity_pool_id": "us-east-1:4fef0e92-8a0e-41f0-bf2c-6238ad53051b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Dk5pJRuw6",
    "aws_user_pools_web_client_id": "496accikck9ur3q7mb4pnc58l2",
    "oauth": {}
};


export default awsmobile;
